import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
    async GetDynamicForm(params) {
        return await http.instanceCommon.get("/FormLayout/GetForm?" + params);
    }

    async SaveDynamicForm(params) {
        return http.instanceCommon.post("/FormLayout/SaveDynamicForm", { postString: params })
    }
    async GetModuleMasterIdByModuleName(params) {
        return await http.instanceCommon.get("/Common/GetModuleMasterIdByModuleName?" + params);
    }
    async GetDDLData(params) {
        return await http.instanceCommon.get("/Common/GetDDLData?" + params);
    }
    async GetViewList(params) {
        return await http.instanceCommon.get("/FormLayout/GetViewList?" + params);
    }
    async GetRequiredSetUpForModule(params) {
        return await http.instanceCommon.get("/Form/GetRequiredSetUpForModule/", { params });
    }
    GetContextMenu(params) {
        return http.instanceCommon.get("/Common/GetContextMenu?" + params);
    }
    async GetConfigurationValueForCompanyByKey(param) {
        return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?" + param);
    }
    async GetHideContextMenuConfig() {
        return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
    }
    CommonUpdateStatus(params) {
        return http.instanceCommon.post("/Common/CommonUpdateStatus", { postString: params })
    }
    async GetFSMListing(params) {
        return await http.instanceFSM.get("/FSM/Listing?" + params);
    }
    async SavePinUnpin(params) {
        return await http.instanceFSM.post("/FSM/SavePinUnpin", { postString: params });
    }
    async GetPinView() {
        return await http.instanceFSM.get("/FSM/GetPinView");
    }
    async TagData(params) {
        return await http.instanceFSM.get("/Ticketing/TagData?" + params);
    }
    async GetRecurringViewDetailAndListing(params) {
        return await http.instanceFSM.get("/FSM/GetRecurringViewDetailAndListing?" + params);
    }
    async GetFsmStatusDDLData(params) {
        return await http.instanceFSM.get("/Common/GetTicketingStatus?" + params);
    }
    async GetConfigurationValueByKey(params) {
        return await http.instanceCommon.get("/Common/GetConfigurationValueForCompanyByKey?" + params);
    }
    async GetDepartmentListbyCompanyForClient() {
        return await http.instanceFSM.get("/Ticketing/GetDepartmentListbyCompanyForClient");
    }
    async GetResolveTicketUserList() {
        return await http.instanceFSM.get("/Ticketing/GetResolveTicketUserList");
    }
    async GetResolveTicketUserListbydept(params) {
        return await http.instanceFSM.get("/Ticketing/GetResolveTicketUserList?" + params);
    }
    async GetPrioritybyCompanyId(params) {
        return await http.instanceFSM.get("/Ticketing/GetPrioritybyCompanyId?" + params);
    }
    async GetProductsList() {
        return await http.instanceFSM.get("/Ticketing/GetProductsList");
    }
    async GetCompanyUserListByCompanyId(params) {
        return await http.instanceFSM.get("/Ticketing/GetCompanyUserListByCompanyId?" + params);
    }
    async GetDDLCategoryForTicket(params) {
        return await http.instanceFSM.get("/Ticketing/GetDDLCategoryForTicket?" + params);
    }
    async GetDDLChannelByCompanyId(params) {
        return await http.instanceFSM.get("/Ticketing/GetDDLChannelByCompanyId?" + params);
    }
    async GetUserList(params) {
        return await http.instanceFSM.get("/Ticketing/GetUserList?" + params);
    }
    async UploadStream() {
        return await http.instanceDocStorage.get("/File/UploadStream");
    }
    async GetClientList() {
        return await http.instanceFSM.get("/Ticketing/GetCompanyClientByCompanyId");
    }
    async GetTimeZoneList() {
        return await http.instanceCommon.get("/Wizard/GetTimeZoneList");
    }
    async GetTimeZoneListing() {
        return await http.instanceFSM.get("/ServiceAppointment/GetTimeZoneForGanttView");
    }
    async GetParentAccountList() {
        return await http.instanceFSM.get("/Ticketing/GetClientParentByCompanyId");
    }
    async GetTwitterAuth() {
        return await http.instanceFSM.get("/MobileFSM/TwitterAuth");
    }
    async GetClientOwnerList(params) {
        return await http.instanceFSM.get("/Ticketing/GetCompanyLeadOwners?" + params);
    }
    async GetRoleList(params) {
        return await http.instanceFSM.get("/Ticketing/GetAllRolesOfUserType?" + params);
    }
    async GetTicketingChannelSourceById(params) {
        return await http.instanceFSM.get("/Ticketing/GetTicketingChannelSourceById?" + params);
    }
    async GetStatusList(params) {
        return await http.instanceCommon.get("/Common/GetStatusList?" + params);
    }
    async AddTicketCategory(params) {
        return await http.instanceFSM.post("/Ticketing/AddTicketCategory?" + params);
    }
    async SaveTicketEsclate(params) {
        return await http.instanceFSM.post("/Ticketing/SaveTicketEsclate", { postString: params });
    }
    async GetUserSkillList(params) {
        return await http.instanceFSM.get("/FSM/GetUserSkillList?" + params);
    }
    async TicketingAssignTickets(params) {
        return await http.instanceFSM.post("/Ticketing/TicketingAssignTickets", { postString: params });
    }
    async TicketingUpdateStatus(params) {
        return await http.instanceFSM.post("/Ticketing/TicketingUpdateStatus", { postString: params });
    }
    async SaveMergeTicket(params) {
        return await http.instanceFSM.post("/Ticketing/SaveMergeTicket", { postString: params });
    }
    async UpdateStatusByStatusMapping(params) {
        return await http.instanceFSM.post("/Ticketing/UpdateStatusByStatusMapping", { postString: params });
    }
    async FollowupList(params) {
        return await http.instanceFSM.get("/Ticketing/FollowUpList?" + params);
    }
    async AddFollowUp(params) {
        return await http.instanceFSM.post("/Ticketing/AddFollowUp", { postString: params });
    }
    async SearchCriteriaListing(params) {

        return await http.instanceCommon.get("/Common/SearchCriteriaListing?" + params);
    }
    async GetFieldName(params) {

        return await http.instanceCommon.get("/Common/GetFieldName?" + params);
    }
    async SaveView(params) {

        return await http.instanceCommon.post("/FormLayout/SaveView", { postString: params });
    }
    async GetOperator(params) {
        return await http.instanceCommon.get("/Common/GetOperator?" + params);
    }
    SearchCriteriaByIdView(params) {

        return http.instanceCommon.get("/Common/ViewFlterById?" + params);
    }
    async SearchCriteriaById(params) {

        return await http.instanceCommon.get("/Common/SearchCriteriaById?" + params);
    }
    async SaveFilter(params) {
        return await http.instanceCommon.post("/Common/SaveFilter", { postString: params })
    }
    GetTicketText(params) {
        return http.instanceFSM.get("/Ticketing/GetTicketText?" + params);
    }
    async RelatedArticleList(params) {
        return await http.instanceFSM.get("/Ticketing/RelatedArticleList?" + params);
    }

    async AssignTags(params) {
        return await http.instanceFSM.post("/Ticketing/AssignTags", { postString: params });
    }
    async RuleEngineListing(params) {
        return await http.instanceFSM.get("/RuleEngine/Listing?" + params);
    }
    async ModuleGroupNameList() {
        return await http.instanceFSM.get("/RuleEngine/ModuleGroupNameList");
    }
    async GetRuleActionItems(params) {
        return await http.instanceFSM.get("/RuleEngine/GetRuleActionItems?" + params);
    }
    async RuleEventItems(params) {
        return await http.instanceFSM.get("/RuleEngine/RuleEventItems?" + params);
    }
    async AddTarget(params) {
        return await http.instanceFSM.get("/RuleEngine/AddTarget?" + params);
    }
    async GetOperators(params) {
        return await http.instanceFSM.get("/RuleEngine/GetOperators?" + params);
    }
    async WorkOrderList(params) {
        return await http.instanceFSM.get("/FSM/WorkOrderList?" + params);
    }
    async GetViewHistoryPendingAcceptanceListing(params) {
        return await http.instanceFSM.get("/FSM/GetViewHistoryPendingAcceptanceListing?" + params);
    }
    async PendingAcceptanceListing(params) {
        return await http.instanceFSM.get("/FSM/PendingAcceptanceListing?" + params);
    }
    async AcceptRejectTickets(params) {
        return await http.instanceFSM.post("/FSM/AcceptRejectTickets", { postString: params });
    }

    async ViewPageDetails(params) {
        return await http.instanceFSM.get("/Common/ViewPageDetails?" + params);
    }


    async ViewTicketDetails(params) {
        return await http.instanceFSM.get("/FSM/ViewTicketDetails?" + params);
    }

    async AcceptRejectTicket(params) {
        return await http.instanceFSM.post("/FSM/AcceptRejectTicket", { postString: params });
    }
    async deleteActivityData(params) {
        return await http.instanceCommon.post("/Common/CommonDelete", { postString: params });
    }
    async DeleteDraftedReply(params) {
        return await http.instanceFSM.post("/Ticketing/DeleteDraftedReply", { postString: params });
    }
    async deleteActivityDataFSM(params) {
        return await http.instanceFSM.post("/Common/CommonDelete", { postString: params });
    }
    async GetTicketDetails(params) {
        return await http.instanceFSM.get("/Common/ViewPageDetails?" + params);
    }
    async GetTicketById(params) {
        return await http.instanceFSM.get("/Ticketing/GetTicketDetailById?" + params);
    }
    // TrackHistoryList(params) {
    //     return http.instanceFSM.get("/TimeTrack/TrackHistoryList?" + params);
    // }
    async SaveHideContextMenuConfig(param) {
        return await http.instanceCommon.post("/Common/SaveHideContextMenuConfig?" + param);
    }
    async GetWorkOrderNoteList(params) {
        return await http.instanceFSM.get("/FSM/GetWorkOrderNoteList?" + params);
    }
    async MarkAsActive(param) {
        return await http.instanceFSM.post("/FSM/MarkAsActive", { postString: param });
    }
    async WorkOrderList(param) {

        return await http.instanceFSM.get("/FSM/WorkOrderList?" + param);
    }
    async GetTicketGroupList(param) {
        return await http.instanceFSM.get("/Ticketing/GetTicketGroupList?" + param);
    }
    async GetRelatedTicketList(param) {
        return await http.instanceFSM.get("/FSM/RelatedTicketList?" + param);
    }
    async GetProductcheckoutList(param) {
        return await http.instanceFSM.get("/FSM/GetProductcheckoutList?" + param);
    }

    async GetTrackHistoryList(param) {
        return await http.instanceFSM.get("/TimeTrack/TrackHistoryList?" + param);
    }

    async ServiceAppointment(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetFsmWorkTypeListing?" + param);
    }
    async TicketFeedbackQuestions(param) {
        return await http.instanceFSM.get("/FSM/TicketFeedbackQuestions?" + param);
    }
    async TicketFeedbackAnswersList(param) {
        return await http.instanceFSM.get("/FSM/TicketFeedbackAnswersList?" + param);
    }
    async SaveUserFeedback(params) {
        return await http.instanceFSM.post("/FSM/SaveUserFeedback", { postString: params });
    }
    async GetFsmCrewList(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetFsmCrewList?" + param);
    }
    async GetFsmIncidentListing(params) {
        return await http.instanceFSM.get("/FSM/GetFsmIncidentListing?" + params);
    }
    async GetTicketFiles(param) {
        return await http.instanceFSM.get("/Ticketing/GetTicketFiles?" + param);
    }
    async EditFollowUp(param) {
        return await http.instanceFSM.get("/Ticketing/EditFollowUp?" + param);
    }
    async TicketClaimList(params) {
        return await http.instanceFSM.get("/Ticketing/TicketClaimList?" + params);
    }
    async GetCallList(params) {
        return await http.instanceFSM.get("/Ticketing/GetCallList?" + params);
    }
    async DownloadFile(params) {
        return await http.instanceCommon.get("/Common/DownloadFile?" + params);
    }
    async GetTicketTypeMappingList(params) {
        return await http.instanceFSM.get("/Ticketing/GetTicketTypeMappingList?" + params);
    }
    async SavePublishDetail(params) {
        return await http.instanceFSM.post("/Ticketing/SavePublishDetail", { postString: params });
    }
    async UpdateMappedTicketStatus(params) {
        return await http.instanceFSM.post("/Ticketing/UpdateMappedTicketStatus", { postString: params });
    }
    async SaveCategoryData(params) {
        return await http.instanceFSM.post("/Ticketing/SaveCategoryData", { postString: params });
    }
    async GetFormList(params) {
        return await http.instanceDocMaker.get("/DocumentMaker/GetFormsList?" + params);
    }
    async SaveUserFormData(params) {
        return await http.instanceDocMaker.post("/DocumentMaker/SaveUserFormData?", { postString: params });
    }
    // async GetFormById(params) {  
    //     return await http.instanceDocMaker.get("/DocumentMaker/GetFormById?" + params);
    // }
    async GetFsmResourceTypeList(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetFsmResourceTypeList?" + param);
    }
    async GetTicketChainList(params) {
        return await http.instanceFSM.get("/Ticketing/GetTicketChainList?" + params);
    }

    async UpdateUserCurrentStatus(param) {
        return await http.instanceFSM.post("/Ticketing/UpdateUserCurrentStatus?" + param);
    }
    async ViewFSMTicketDetails(param) {
        return await http.instanceFSM.get("/FSM/ViewFSMTicketDetails?" + param);
    }
    async FetchUserCurrentStatus() {
        return await http.instanceFSM.get("/FSM/FetchUserCurrentStatus");
    }
    async GetNoteList(param) {
        return await http.instanceFSM.get("/Ticketing/GetNoteList?" + param);
    }
    async GetThreadsList(param) {
        return await http.instanceFSM.get("/Ticketing/GetThreadsList?" + param);
    }
    async SaveMapCityLatLng(param) {
        return await http.instanceFSM.post("/FSM/SaveMapCityLatLng", { postString: param });
    }
    async updatestatus(params) {
        return await http.instanceFSM.post("/Ticketing/UpdateStatus?" + params);
    }
    async UpdateSubStatus(params) {
        return await http.instanceFSM.post("/FSM/UpdateStatus", { postString: params });
    }
    async TicketUpdate(params) {
        return await http.instanceFSM.post("/Ticketing/TicketUpdate", params);
    }
    async GetMapCityListByUserId(param) {
        return await http.instanceFSM.get("/FSM/GetMapCityListByUserId?" + param);
    }

    async MapType(param) {
        return await http.instanceFSM.get("/FSM/MapType?" + param);
    }
    async MapList(param) {
        return await http.instanceFSM.get("/FSM/MapList?" + param);
    }
    async SaveMap(param) {
        return await http.instanceFSM.post("/FSM/SaveMap?" + param);
    }
    async GetTransformPendingList(param) {
        return await http.instanceFSM.get("/FSM/TransformPendingList?" + param);
    }
    async ZoneList(params) {
        return await http.instanceFSM.get("/FSM/ZoneList?" + params);
    }
    async AssignTransferTicket(params) {
        return await http.instanceFSM.get("/Ticketing/AssignTransferTicket?" + params);
    }
    async GetTicketListData(params) {
        return await http.instanceFSM.get("/Ticketing/GetTicketListData?" + params);
    }
    async GetDepartmentListForSupport() {
        return await http.instanceFSM.get("/Ticketing/GetDepartmentListForSupport");
    }
    async GetUserListByDepId(params) {
        return await http.instanceFSM.get("/Ticketing/GetUserListByDepId?" + params);
    }
    // async UpdateUserCurrentStatus(params){
    //     return await http.instanceFSM.post("/Ticketing/UpdateUserCurrentStatus?" + params);
    // }
    async GetCompanyParentClientCustomListByCompanyIdForGroup() {
        return await http.instanceFSM.get("/TicketReport/GetCompanyParentClientCustomListByCompanyIdForGroup_New");
    }

    async GetAllDepartment() {
        return await http.instanceFSM.get("/TicketReport/GetAllDepartment")
    }
    GetUserLicenseModulesReport() {
        return http.instanceFSM.get("/TicketReport/GetUserLicenseModulesReport");
    }
    async ApplyView(params) {
        return await http.instanceCommon.post("/FormLayout/ApplyView", { postString: params });
    }
    async ClearView(params) {
        return await http.instanceCommon.post("/FormLayout/ClearView", { postString: params });
    }
    async ViewListUsers(params) {
        return await http.instanceCommon.get("/FormLayout/ViewListUsers?" + params);
    }
    async ViewListFields(params) {
        return await http.instanceCommon.get("/FormLayout/ViewListFields?" + params);
    }
    async GetViewById(params) {
        return await http.instanceCommon.get("/FormLayout/GetViewById?" + params);
    }
    async TransferOrAssignTicket(params) {
        return await http.instanceFSM.post("/Ticketing/TransferOrAssignTicket", params);
    }
    async FetchDataByWorktypeId(params) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetWorkTypeById?" + params);
    }
    async FetchDDLTicketTypeList(params) {
        return await http.instanceFSM.get("/Ticketing/BindDDLTicketTypeList?" + params);
    }
    async SaveBillingTicketTypeType(params) {
        return await http.instanceFSM.post("/Ticketing/SubmitTicketTypeMapping", { postString: params });
    }
    async FetchResourceTypeById(params) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetResourceTypeById?" + params);
    }
    async FetchResourcesByResourceTypeById(params) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetResourcesByResourceTypeId?" + params);
    }
    async FetchResourceTypeId(params) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetSAByResourceTypeId?" + params);
    }

    async SaveMapStatusTicketType(params) {
        return await http.instanceFSM.post("/Ticketing/SaveTicketStatusMapping", { postString: params });
    }
    async SaveWorkType(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveWorkType", { postString: params });
    }
    async FetchTicketStatusList(params) {
        return await http.instanceFSM.get("/Ticketing/GetTicketStatusMapping?" + params);
    }
    async FetchDataByResourcetypeId(params) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmResourceTypeDetails?" + params);
    }
    async FetchFSMTicketingSetupSources(params) {
        return await http.instanceFSM.get("/Ticketing/GetFSMTicketingSetupSources?" + params);
    }
    async SaveResourceTypeWithResources(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveResourceTypeWithResources", { postString: params });
    }
    async SaveResourceTypeResources(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveResource", { postString: params });
    }
    async FetchDataByCrewId(params) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmCrewDetails?" + params);
    }
    async FetchTicketingChannelOptionList() {
        return await http.instanceFSM.get("/Ticketing/GetTicketingChannelOptionList");
    }
    async SaveTicketingCrew(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveCrew", { postString: params });
    }
    async FetchDataByIncidentId(params) {
        return await http.instanceFSM.get("/FSM/GetIncidentDetails?" + params);
    }
    async UpdateRecurringDetail(params) {
        return await http.instanceFSM.post("/FSM/UpdateRecurringDetail", { postString: params });
    }
    async DeleteIncidentAttachment(params) {
        return await http.instanceFSM.post("/FSM/DeleteIncidentAttachment?" + params);
    }
    async DeleteReportIncident(params) {
        return await http.instanceFSM.post("/FSM/DeleteReportIncident?", { postString: params });
    }
    async SaveIncidentType(params) {
        return await http.instanceFSM.post("/FSM/SubmitIncidentType", { postString: params });
    }
    async GetPriorityData(params) {
        return await http.instanceFSM.get("/Ticketing/GetPriorityData");
    }
    async GetTicketTypeList(params) {
        return await http.instanceFSM.get("/Ticketing/GetTicketTypeList?" + params);
    }
    async SubmitTicketType(params) {
        return await http.instanceFSM.post("/Ticketing/SubmitTicketType", { postString: params });
    }
    async SubmitTicketingChannelConfiguration(params) {
        return await http.instanceFSM.post("/Ticketing/SaveTicketingChannelConfiguration", { postString: params });
    }
    async GetAvailableTicketTypes() {
        return await http.instanceFSM.get("/Ticketing/GetAvailableTicketTypes");
    }
    async BindDDLTicketTypeList(params) {
        return await http.instanceFSM.get("/Ticketing/BindDDLTicketTypeList?" + params);
    }
    async SubmitTicketTypeMapping(params) {
        return await http.instanceFSM.post("/Ticketing/SubmitTicketTypeMapping", { postString: params });
    }
    async GetAssignedTicketTypeList(params) {
        return await http.instanceFSM.get("/Ticketing/GetAssignedTicketTypeList?" + params);
    }
    async EditNote(params) {
        return await http.instanceFSM.post("/Ticketing/EditNote", { postString: params });
    }
    async UploadFile(params) {
        return await http.instanceFSM.post("/Common/UploadFile", { postString: params });
    }
    async AddNote(params) {
        return await http.instanceFSM.post("/Ticketing/AddNote", { postString: params });
    }
    async AddClaim(params) {
        return await http.instanceFSM.post("/Ticketing/AddClaim", { postString: params });
    }
    async ApproveRejectClaim(params) {
        return await http.instanceFSM.post("/Ticketing/ApproveRejectClaim", { postString: params });
    }
    async TicketReply(params) {
        return await http.instanceFSM.post("/Ticketing/TicketReply", { postString: params });
    }
    async FsmGetCrewById(params) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetCrewById?" + params);
    }
    async FsmGetResourcesByCrewId(param) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetResourcesByCrewId?" + param);
    }
    async FsmGetServiceAppointmentByCrew(param) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetServiceAppointmentByCrew?" + param);
    }
    async FsmGetCrewResourceByCrewId(params) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetCrewResourceByCrewId?" + params);
    }
    async SaveCrewResource(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveCrewResource", { postString: params });
    }
    async SaveMap(params) {
        return await http.instanceFSM.post("/FSM/SaveMap", { postString: params });
    }
    async FsmGetWorktypeDetailById(params) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetWorktypeDetailById?" + params);
    }
    async FsmGetServiceAppointmentByWorkType(param) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetServiceAppointmentByWorkType?" + param);
    }
    async GetFsmWorktypeStatusCodeMappingList(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetFsmWorktypeStatusCodeMappingList?" + param);
    }
    async FsmGetSkillMappingByWorkType(param) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetSkillMappingByWorkType?" + param);
    }
    async GetSkillByWorkTypeId(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetSkillByWorkTypeId?" + param);
    }
    async SaveWorkTypeSkillMapping(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveWorkTypeSkillMapping", { postString: params });
    }
    async GetSkill() {
        return await http.instanceFSM.get("/Common/GetSkill");
    }
    async FsmGetProductMappingByWorkType(param) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetProductMappingByWorkType?" + param);
    }
    async GetProductByWorkTypeId(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetProductByWorkTypeId?" + param);
    }
    async SaveWorkTypeProductMapping(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveWorkTypeProductMapping", { postString: params });
    }
    async GetFsmCdcList(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetFsmCdcList?" + param);
    }
    async SaveCdcCode(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveCdcCode", { postString: params });
    }
    async GetDepartment() {
        return await http.instanceFSM.get("/Common/GetDepartment");
    }
    async AddTicketGroup(params) {
        return await http.instanceFSM.post("/FSM/AddTicketGroup?" + params);
    }
    async IsExtraMapFeature() {
        return await http.instanceFSM.get("/ServiceAppointment/IsExtraMapFeature");
    }
    async FsmGetFormMappingByWorkType(param) {
        return await http.instanceFSM.get("/ServiceAppointment/FsmGetFormMappingByWorkType?" + param);
    }
    async SaveFormMapping(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveFormMapping", { postString: params });
    }
    async GetGroupTicketData(params) {
        return await http.instanceFSM.get("/FSM/GetTicketGroup?" + params);
    }
    async GetTicketGroupsForDropdownList() {
        return await http.instanceFSM.get("/FSM/GetTicketGroupsForDropdownList");
    }
    async AddTicketServiceBoard(params) {
        return await http.instanceFSM.post("/FSM/AddTicketServiceBoard?" + params);
    }
    async GetChainData(params) {
        return await http.instanceFSM.get("/FSM/GetChainData?" + params);
    }
    async ViewServiceAppointment(params) {
        return await http.instanceFSM.get("/ServiceAppointment/ViewServiceAppointment?" + params);
    }
    async GetFsmResourceListBySA_Id(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetFsmResourceListBySA_Id?" + param);
    }
    async MapUnMapResource(params) {
        return await http.instanceFSM.post("/ServiceAppointment/MapUnMapResource", { postString: params });
    }
    async GetDDLDataFSM(params) {
        return await http.instanceFSM.get("/Common/GetDDLData?" + params);
    }
    async GetFSMDDLData(params) {
        return await http.instanceFSM.get("/Common/GetFSMDDLData?" + params);
    }
    async GetSingleWorkOrder(params) {
        return await http.instanceFSM.get("/FSM/GetSingleWorkOrder?" + params);
    }
    async MarkAsDone(params) {
        return await http.instanceFSM.post("/FSM/MarkAsDone", { postString: params });
    }
    async SaveResource(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveResource", { postString: params });
    }
    async SaveMapResource(params) {
        return await http.instanceFSM.post("/ServiceAppointment/MapUnMapResource", { postString: params });
    }
    async ServiceAppointmentListing(params) {
        return await http.instanceFSM.get("/ServiceAppointment/ServiceAppointmentListing?" + params);
    }
    async ManageServiceAppointment(param) {
        return await http.instanceFSM.get("/ServiceAppointment/ManageServiceAppointment?" + param);
    }
    async CheckPrivilege(params) {
        return await http.instanceCommon.get("/Common/HasPermission?" + params);
    }
    async DeleteMultipleOrSingleRecords(param) {
        return await http.instanceFSM.post("/ServiceAppointment/DeleteMultipleOrSingleRecords", { postString: param });
    }
    async SearchCriteriaListingCrm(params) {

        return await http.instanceCRM.get("/Form/SearchCriteriaListing?" + params);
    }
    async GetFieldNameCrm(params) {

        return await http.instanceCRM.get("/Form/GetFieldName?" + params);
    }
    async SearchCriteriaByIdCrm(params) {

        return await http.instanceCRM.get("/Form/SearchCriteriaById?" + params);
    }
    async GetOperatorCrm(params) {

        return await http.instanceCRM.get("/Form/GetOperator?" + params);
    }
    async SaveFilterCrm(params) {

        return await http.instanceCRM.post("/Form/SaveFilter", { postString: params })
    }
    async getDDLDataCrm(params) {
        return await http.instanceCRM.get("/Form/GetDDLData?" + params);
    }
    async GetDefaultView() {
        return await http.instanceFSM.get("/ServiceAppointment/GetDefaultView?");
    }
    async SaveGanttViewFilter(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveGanttViewFilter", { postString: params });
    }
    async CommonCommonDelete(params) {
        return await http.instanceCommon.post("/Common/CommonDelete", { postString: params });
    }
    async DeleteData(params) {
        return await http.instanceCommon.post("/Common/Delete?" + params);
    }
    async GetCrewResourceList(params) {
        return await http.instanceFSM.get("/ServiceAppointment/GetCrewResourceList?" + params);
    }
    async GetPolicyListSLA(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetPolicyListSLA?" + param);
    }
    async MovePolicyDisplayOrder(params) {
        return await http.instanceFSM.post("/ServiceAppointment/MovePolicyDisplayOrder", { postString: params });
    }
    async GetUserTimeZoneDisplayName() {
        return await http.instanceFSM.get("/ServiceAppointment/GetUserTimeZoneDisplayName?");
    }
    async FetchTimeZoneList() {
        return await http.instanceFSM.get("/ServiceAppointment/GetTimeZoneForGanttView?");
    }
    async SaveWorkOrder(params) {
        return await http.instanceFSM.post("/FSM/SaveWorkOrder", { postString: params });
    }
    async SaveTrackHistory(params) {
        return await http.instanceFSM.post("/TimeTrack/SaveTrackHistory", { postString: params });
    }
    async StopTrackHistory(params) {
        return await http.instanceFSM.post("/TimeTrack/StopTrackHistory", { postString: params });
    }
    async GetUnscheduledServiceAppointmentList(params) {
        return await http.instanceFSM.get("/ServiceAppointment/GetUnscheduledServiceAppointmentList?" + params);
    }
    async GetTicketClientAddress(param) {
        return await http.instanceFSM.get("/Ticketing/GetTicketClientAddress?" + param);
    }
    async GetFsmStatusCodeList(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetFsmStatusCodeList?" + param);
    }
    async getRelatedSAList(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetRelatedServiceAppointments?" + param);
    }
    async getResourceCrewData(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetResourceCrewData?" + param);
    }
    async SaveStatusCode(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveStatusCode", { postString: params });
    }
    async getStatusList(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetSADropdownListByType?" + param);
    }
    async GetSADetailById(param) {
        return await http.instanceFSM.get("/ServiceAppointment/GetSADetailById?" + param);
    }
    async AddScheduledAppointment(params) {
        return await http.instanceFSM.post("/ServiceAppointment/AddScheduledAppointment", { postString: params });
    }
    async GetFsmWorktypeStatusCodeFormMappingList(params) {
        return await http.instanceFSM.get("/ServiceAppointment/GetFsmWorktypeStatusCodeFormMappingList?" + params);
    }
    async SaveFormStatusCodeMapping(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveFormStatusCodeMapping", { postString: params });
    }
    async TrackByUserId(params) {
        return await http.instanceFSM.get("/FSM/TrackByUserId?" + params);
    }
    async GetSACalenderList(params) {
        return await http.instanceFSM.get("/ServiceAppointment/GetSACalenderList?" + params);
    }
    async GetZoneById(params) {
        return await http.instanceFSM.get("/FSM/GetZoneById?" + params);
    }
    async GetUserSkillList(params) {
        return await http.instanceFSM.get("/FSM/GetUserSkillList?" + params);
    }
    async GetPolicyDetailById(params) {
        return await http.instanceFSM.get("/Ticketing/GetPolicyDetailById?" + params);
    }
    async SaveAndSearchGanttFilter(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveAndSearchGanttFilter", { postString: params });
    }
    async UploadGeoJSON(params) {
        return await http.instanceFSM.post("/FSM/UploadGeoJSON", { postString: params });
    }
    async GetServiceAppointmentByWorkOrderId(params) {
        return await http.instanceFSM.get("/ServiceAppointment/GetServiceAppointmentByWorkOrderId?" + params);
    }
    async AttachmentList(param) {
        return await http.instanceFSM.get("/Ticketing/AttachmentList?" + param);
    }
    async TrackHistoryList(param) {
        return await http.instanceFSM.get("/TimeTrack/TrackHistoryList?" + param);
    }
    async FetchReportIncidentRecords(param) {
        return await http.instanceFSM.get("/FSM/GetIncidentList?" + param);
    }
    async FetchViewStatusHistoryRecords(param) {
        return await http.instanceFSM.get("/FSM/WorkOrderStatusHistoryList?" + param);
    }
    async ApproveRejectStatus(params) {
        return await http.instanceFSM.post("/FSM/ApproveRejectStatus", { postString: params });
    }
    async CheckConfigurationValueByKey(params) {
        return await http.instanceFSM.get("/FSM/CheckConfigurationValueByKey?" + params);
    }
    async MarkAsDone(params) {
        return await http.instanceFSM.post("/FSM/MarkAsDone", { postString: params });
    }
    async GetCrewMemberListing(params) {
        return await http.instanceFSM.get("/ServiceAppointment/GetCrewMemberListing?" + params);
    }
    async GetFSMDDLData(param) {
        return await http.instanceFSM.get("/Common/GetFSMDDLData?" + param);
    }
    async SaveRuleEngine(params) {
        return await http.instanceFSM.post("/RuleEngine/SaveRuleEngine", { postString: params });
    }
    async SaveTicketPolicySLA(params) {
        return await http.instanceFSM.post("/RuleEngine/SaveTicketPolicySLA", { postString: params });
    }
    async AddReportIncident(param) {
        return await http.instanceFSM.post("/FSM/AddReportIncident", { postString: param });
    }
    async IncidentTypeDetails(param) {
        return await http.instanceFSM.get("/FSM/IncidentTypeDetails?" + param);
    }
    async GetFilterDDLSelectedValue() {
        return await http.instanceFSM.get("/ServiceAppointment/GetFilterDDLSelectedValue/");
    }
    async GetRuleDetaildByRuleId(params) {
        return await http.instanceFSM.get("/RuleEngine/GetRuleDetaildByRuleId?" + params);
    }
    async GetRuleActionFields(params) {
        return await http.instanceFSM.get("/RuleEngine/GetRuleActionFields?" + params);
    }
    async GetModuleList(params) {
        return await http.instanceDocMaker.get("/DocumentMaker/GetModuleList");
    }
    async GetSubModuleListByModuleId(params) {
        return await http.instanceDocMaker.get("/DocumentMaker/GetSubModuleListByModuleId?" + params);
    }
    async GetSystenFiledList(params) {
        return await http.instanceDocMaker.get("/DocumentMaker/GetSystenFiledList?" + params);
    }
    async SaveEditFormBuilderForm(params) {
        return await http.instanceDocMaker.post("/DocumentMaker/SaveEditFormBuilderForm", { postString: params });
    }
    async GetFormById(params) {
        return await http.instanceDocMaker.get("/DocumentMaker/GetFormById?" + params);
    }
    async DeleteMultipleRecords(params) {
        return await http.instanceDocMaker.post("/DocumentMaker/DeleteMultipleRecords", { postString: params });
    }
    async RemoveTagData(params) {
        return await http.instanceFSM.get("/Ticketing/RemoveTagData?" + params);
    }
    async SaveWorktypeStatusCodeMapping(params) {
        return await http.instanceFSM.post("/ServiceAppointment/SaveWorktypeStatusCodeMapping", { postString: params });
    }
    async UpdateZone(params) {
        return await http.instanceFSM.post("/FSM/UpdateZone", { postString: params });
    }
    async AddEditServiceAppointment(params) {
        return await http.instanceFSM.post("/ServiceAppointment/AddEditServiceAppointment", { postString: params });
    }
    async CheckExtraFeatures(params) {
        return http.instanceCommon.get("/Wizard/CheckExtraFeatures?" + params);
    }
    async GetEventResultType(params) {
        return await http.instanceFSM.get("/RuleEngine/GetEventResultType?" + params);
    }
    async SaveTicketManageLayout(params) {
        return await http.instanceFSM.post("/Ticketing/SaveTicketManageLayout", { postString: params });
    }
    async FrontSiteUrlHostName(params) {

        return await http.instanceFSM.get("/Ticketing/GetConfgurationValueByKey?" + params);
    }
    // async GetConfigurationData(params) {
    //     return http.instanceCommon.get("/Common/GetModuleKeys?" + params);
    // }
    async GetTimeZone(params) {
        return http.instanceCommon.get("/Common/GetTimeZone", params);
    }
    async GetCurrency(params) {
        return http.instanceCommon.get("/Common/GetCurrency?" + params);
    }
    async ManageDocUserStorageSettingData(params) {
        return http.instanceCommon.post("/Common/ManageDocUserStorageSettingData", params);
    }
    async GetConfigNumberJsonData(params) {
        return await http.instanceCRM.get("/Form/GetConfigNumberJsonData?" + params);
    }
    async SaveConfiguringJsonData(params) {
        return await http.instanceCRM.post("/Form/SaveConfiguringJsonData", { postString: params });
    }
    async GetClientOwner(params) {
        return await http.instanceCommon.get("/Common/GetClientOwner?" + params);
    }
    async GetBlobUrl(params) {
        return http.instanceCommon.get("/Common/GetBlobUrl?" + params);
    }
    async GetConfigurationData(params) {
        return http.instanceCommon.get("/Common/GetModuleKeys?" + params);
    }
    async GetLayoutSetting() {
        return await http.instanceFSM.get("/Ticketing/GetLayoutSetting");
    }
    async AddClient(params) {
        return await http.instanceCommon.post("/Users/AddClient", { postString: params });
    }
    async GetTemplateList(params) {
        return await http.instanceFSM.get("/Ticketing/GetTemplateList?" + params);
    }
    async SaveCannedReply(params) {
        return await http.instanceFSM.post("/Ticketing/SaveCannedReply", { postString: params });
    }
    async GetTemplateDetails(params) {
        return await http.instanceFSM.get("/Ticketing/GetSelectedReplyTemplate?" + params);
    }
    async GetClientList(params) {
        return await http.instanceFSM.get("/Ticketing/GetCompanyClient?" + params);
    }
    async GetTicketStatus() {
        return await http.instanceFSM.get("/Ticketing/GetTicketStatus");
    }
    async ReportMain(params) {
        return await http.instanceCommon.post("Report/ReportMain", { postString: params });
    }
    async GetKnowledgeBaseList(params) {
        return await http.instanceFSM.get("/KnowledgeBase/GetKnowledgeBaseList?"  + params);
    }
    async GetArticleListing(params) {
        return await http.instanceFSM.get("/KnowledgeBase/GetArticleListing?" + params);
    }
    async GetCategoryDDL() {
        return await http.instanceFSM.get("/KnowledgeBase/GetCategoryDDL");
    }
    async CopyandMoveArticles(params) {
        return await http.instanceFSM.get("/KnowledgeBase/CopyandMoveArticles?" + params);
    }
    async GetUsersByCompanyId(params) {
        return await http.instanceFSM.get("/KnowledgeBase/GetUsersByCompanyId?" + params);
    }
    async GetArticleById(params) {
        return await http.instanceFSM.get("/KnowledgeBase/GetArticleById?" + params);
    }
    async SaveUpdateArticle(params) {
        return await http.instanceFSM.post("KnowledgeBase/SaveEditArticle", { postString: params });
    }
    async displayArticleDetails(params) {
        return await http.instanceFSM.get("/KnowledgeBase/displayArticleDetails?" + params);
    }
    async AddTicketPriority(params) {
        return await http.instanceFSM.post("Common/AddTicketPriority?" + params);
    }
    async AddTicketAccount(params) {
        return await http.instanceFSM.post("Common/AddAccount?" + params);
    }
    async GetTimeZoneByTimeZone() {
        return await http.instanceFSM.get("ServiceAppointment/GetTimeZoneByTimeZone/");
    }
    async GetDefaultUserTimeZone() {
        return await http.instanceFSM.get("ServiceAppointment/GetDefaultUserTimeZone/");
    }
    async GetTimeZoneForGanttView() {
        return await http.instanceFSM.get("ServiceAppointment/GetTimeZoneForGanttView/");
    }
    async SaveTicketConclusion(params) {
        return await http.instanceFSM.post("/Ticketing/SaveTicketConclusion", { postString: params });
    }
    async LoadConclusionTab(params) {
        return await http.instanceFSM.get("/Ticketing/LoadConclusionTab?" + params);
    }
    async GetTicketConclusionData(params) {
        return await http.instanceFSM.get("/Ticketing/GetTicketConclusionData?" + params);
    }
    async GetServiceDetail(params) {
        return await http.instanceFSM.get("/Ticketing/GetServiceDetail?" + params);
    }
    async GetSACalenderListNew(params) {
        return await http.instanceFSM.get("ServiceAppointment/GetSACalenderListNew?" + params);
    }
    async GetStorageSetupStatusDetail(params) {
        return http.instanceFSM.get("/Common/GetStorageSetupStatusDetail?" + params);
    }
    async GetServiceListing(params) {
        return await http.instanceFSM.get("/Ticketing/GetServiceListing?" + params);
    }
    async UpdatePublishDetail(params) {
        return await http.instanceFSM.post("/Ticketing/UpdatePublishDetail?" + params);
    }
    async GetServiceListingById(params) {
        return await http.instanceFSM.get("/Ticketing/GetServiceListingById?" + params);
    }
    async InsertNotification(param) {
        return await http.instanceCommon.post("/Notification/InsertNotification", { postString: param });
    }
    async ManageCategoryList(params) {
        return await http.instanceFSM.get("/KnowledgeBase/ManageCategory?" + params);
    }
    async CategoryListDDL() {
        return await http.instanceFSM.get("/KnowledgeBase/GetArticleSubArticelList");
    }
    async SubCategoryListDDL() {
        return await http.instanceFSM.get("/KnowledgeBase/GetSubCategoryList");
    }
    async SaveUpdateCategory(param) {
        return await http.instanceFSM.post("/KnowledgeBase/SaveUpdateCategory", { postString: param });
    }
    async GetDetails(params) {
        return await http.instanceFSM.get("/KnowledgeBase/GetCategoryDetails?" + params);
    }
    async AddServiceForm(params) {
        return await http.instanceFSM.post("/Ticketing/AddServiceForm?" + params);
    }
    async ShowGpxPlayer(params) {
        return await http.instanceCommon.get("/Common/ShowGpxPlayer?" + params);
    }
    // async SaveServiceFileUpload(param) {
    //     return await http.instanceCRM.post("/File/UploadFile", { postString: param });
    // }
    async ImportServicePost(params) {
        return await http.instanceFSM.post("/Ticketing/ImportServicePost", params);
    }
    async GetServiceExcelColumn(params) {
        return await http.instanceFSM.get("/Ticketing/GetServiceExcelColumn?" + params);
    }
    async GetServiceTableColumn() {
        return await http.instanceFSM.get("/Ticketing/GetServiceTableColumn?");
    }
    async GetWorkTypeTableValue(params) {
        return await http.instanceFSM.get("/Ticketing/GetWorkTypeTableValue?" + params);
    }
    async SaveWorkTypeMapping(param) {
        return await http.instanceFSM.post("/Ticketing/SaveWorkTypeMapping", { postString: param });
    }
}

export default new DataService();
